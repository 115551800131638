<template>
    <div class="box">
        <h2>
            PDF Formular erstellen
        </h2>
        <form @submit.prevent="save()">
            <div class="body">
                <div class="form-group form-float">
                    <input v-model="pdfForm.label" v-validate="'required'" id="label" name="label" placeholder=" " type="text" />
                    <label for="label">Bezeichner</label>
                    <span class="error" v-show="errors.has('label')">{{ errors.first("label") }}</span>
                </div>

                <hr />

                <div class="button-row">
                    <button type="submit" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pdfForm: {
                id: "",
                product: "",
                label: "",
                pages: [],
                customFields: []
            }
        };
    },
    methods: {
        create() {
            let request = {
                product: this.pdfForm.product,
                label: this.pdfForm.label
            };

            this.$api.post("/docs/category/" + this.$route.params.product + "/pdfform", request).then(
                response => {
                    // Template created
                    this.$snotify.success("PDF Form erstellt");
                    this.$router.push({ name: "docs_pdfform_edit", params: { product: this.$route.params.product, id: response.data.data.id } });
                },
                () => {
                    // Create failed
                    this.$snotify.error("PDF Form creation failed");
                }
            );
        },
        save() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                this.create();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
table.customFields {
    > tbody > tr:first-child td {
        border-top: 0;
    }

    td {
        vertical-align: top;

        table td {
            border-top: 0;
        }

        &.remove {
            vertical-align: middle;
            border-left: 1px solid #e5ebec;
        }

        &.subtable {
            padding: 0;
        }
    }
}

div.cols {
    display: flex;
    align-items: flex-start;

    :first-child {
        flex-grow: 1;
        margin-right: 1rem;
    }

    &.cols-equal {
        > * {
            flex-grow: 1;
        }
    }
}

h3.group-header {
    display: flex;
    span {
        flex-grow: 1;
    }
    div.toolbar {
        display: inline;

        i {
            cursor: pointer;
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

span.small {
    font-size: 0.7rem;
    line-height: 1rem;
}
</style>
